import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDocs, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { FormControl, OutlinedInput, InputAdornment } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableSortLabel } from "@mui/material";
import { Tooltip } from "@mui/material";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const isSooperDealActive = (startDate, endDate, price) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (now < start) return false;
  if (!price) return false;
  return now < end;
};

const EditListings = () => {
  const environment = useContext(EnvContext);
  const { id } = useParams();
  const db = useFirestore();
  //
  const inputRefs = useRef({});

  //
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [products, setProducts] = useState([]);
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "product_name",
    direction: "asc",
  });

  const formatUID = (uid, totalLength = 8) => String(uid).padStart(totalLength, "0");

  useEffect(() => {
    const fetchListings = async () => {
      if (!id) return;
      setLoading(true);
      try {
        const storeCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);
        const storeSnapshot = await getDocs(storeCollectionRef);
        const storeData = storeSnapshot.docs.find((doc) => doc.id === id);
        setStore(storeData ? storeData.data() : null);

        // Fetch only listings for this specific seller
        const listingsCollectionRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
        const listingsSnapshot = await getDocs(listingsCollectionRef);
        const storeListings = listingsSnapshot.docs
          .filter((doc) => doc.id.startsWith(`${id}_`)) // Only include listings that belong to this seller
          .map((doc) => ({ id: doc.id.split("_")[1], ...doc.data() })); // Extract product ID from doc ID and map the rest of the data

        setProducts(storeListings); // Set only the listings, no need for product combination
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, [id, db, collectionPath]);

  const handleInputChange = (productId, field, value, type = "text") => {
    const updatedProducts = products.map((product) => {
      if (product.id === productId) {
        let updatedValue = value;

        if (type === "number") {
          if (value === "" || value === null) {
            updatedValue = null; // Allow clearing the field
          } else if (/^\d*\.?\d{0,2}$/.test(value)) {
            // Allow valid numbers with up to 2 decimal places
            updatedValue = value; // Keep the string for intermediate input
          } else {
            return product; // Ignore invalid input
          }
        }

        // Convert date fields to ISO string
        if (type === "date" || field.includes("date")) {
          updatedValue = value === "" ? null : value;
        }

        return { ...product, [field]: updatedValue };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const listingRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);

      for (const product of products) {
        const docId = `${id}_${product.id}`;
        const listingDocRef = doc(listingRef, docId);

        // Convert valid numbers from strings and clean up invalid values
        const productDataToSave = Object.entries(product).reduce((acc, [key, value]) => {
          if (typeof value === "string" && /^\d*\.?\d*$/.test(value)) {
            acc[key] = parseFloat(value); // Convert valid strings to numbers
          } else if (typeof value === "number" && isNaN(value)) {
            acc[key] = null; // Replace NaN with null
          } else {
            acc[key] = value; // Keep other values as is
          }
          return acc;
        }, {});

        // Save to Firestore
        await setDoc(listingDocRef, productDataToSave, { merge: true });
      }

      toast.success("Settings saved successfully");
    } catch (error) {
      toast.error("Error saving changes");
      console.error("Error saving changes:", error);
    } finally {
      setLoading(false);
    }
  };

  //
  const handleSortRequest = (property) => {
    const isAsc = sortConfig.key === property && sortConfig.direction === "asc";
    setSortConfig({ key: property, direction: isAsc ? "desc" : "asc" });
  };

  const sortedProducts = [...products].sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    const aValue = a[sortConfig.key] || "";
    const bValue = b[sortConfig.key] || "";

    if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * order;
    }

    return aValue.toString().localeCompare(bValue.toString()) * order;
  });

  //
  const handleKeyDown = (e, rowIndex, columnIndex) => {
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent default Tab behavior

      // Determine if Shift key is pressed
      const direction = e.shiftKey ? -1 : 1;
      const nextRow = rowIndex + direction;

      // Focus the next input in the same column, if it exists
      const nextInput = inputRefs.current[`${nextRow}-${columnIndex}`];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  return (
    <div style={styles.container}>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      {store ? (
        <>
          <div style={styles.header}>
            <h2 style={{ fontWeight: "200" }}>
              Listing <span style={{ fontWeight: "600" }}>{store?.id ?? store?.seller_name}</span>
            </h2>
            <div style={styles.buttonGroup}>
              <Link to={`/main/listings/edit/${id}`}>
                <button style={styles.button}>Edit Listing</button>
              </Link>
              <Link to={`/main/listings/sooper-deals/${id}`}>
                <button style={styles.button}>Sooper Deals</button>
              </Link>
            </div>
          </div>

          {sortedProducts.length > 0 ? (
            <form onSubmit={saveChanges} style={styles.form}>
              <div style={{ padding: "20px" }}>
                <table style={styles.table}>
                  <thead>
                    <tr style={{ backgroundColor: "rgba(49,57,66,.02)" }}>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "product_name"}
                          direction={sortConfig.key === "product_name" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("product_name")}
                        >
                          Product Name
                        </TableSortLabel>
                      </th>

                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "brand_name"}
                          direction={sortConfig.key === "brand_name" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("brand_name")}
                        >
                          Brand
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th }}>
                        <TableSortLabel
                          active={sortConfig.key === "listing_price"}
                          direction={sortConfig.key === "listing_price" ? sortConfig.direction : "asc"}
                          onClick={() => handleSortRequest("listing_price")}
                        >
                          Price
                        </TableSortLabel>
                      </th>
                      <th style={{ ...styles.th, padding: 0 }}>LpO</th>
                      <th style={{ ...styles.th, padding: 0 }}>St. Amount</th>
                      <th style={{ ...styles.th, padding: 0 }}>Sale Price</th>
                      <th style={{ ...styles.th, padding: 0 }}>Sale %</th>
                      <th style={{ ...styles.th, padding: 0 }}>Sale Start Date</th>
                      <th style={{ ...styles.th, padding: 0 }}>Sale End Date</th>
                      <th style={{ ...styles.th, padding: 0 }}>Product Description</th>
                      <th
                        style={{
                          ...styles.th,
                          width: "50px",
                          textAlign: "center",
                        }}
                      >
                        N/A
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedProducts.map((product, rowIndex) => (
                      <Tooltip
                        title={product.product_name || ""}
                        // position
                        placement="top-start"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [10, -30], // Adjust the first value to move left (-20px) or right (+20px)
                              },
                            },
                          ],
                        }}
                      >
                        <tr key={{ ...product.id }} style={{}}>
                          <td
                            style={{
                              ...styles.td,
                              ...styles.formInput,
                              width: 200,
                              backgroundColor: "transparent",
                              border: 0,
                              display: "flex",
                              alignContent: "flex-start",
                              padding: "0px 0px 10px 10px",
                            }}
                          >
                            {isSooperDealActive(
                              product?.sooperdeal_start_date,
                              product?.sooperdeal_expiration_date,
                              product?.sooperdeal_price,
                              product?.sooperdeal_limit
                            ) && (
                              <div
                                style={{
                                  position: "absolute",
                                  marginLeft: -25,
                                  marginTop: -3,
                                }}
                              >
                                <dotlottie-player
                                  src="https://lottie.host/02410fb0-fea9-4f42-8988-2b1fdbee0e1c/Ub51tC0G7m.json"
                                  background="transparent"
                                  speed="1"
                                  loop
                                  autoplay
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                  }}
                                ></dotlottie-player>
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: 14,
                              }}
                            >
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "200px", // Adjust the width to your desired limit
                                  cursor: "pointer",
                                }}
                              >
                                {product.product_name || ""}
                              </span>
                              <span style={{ fontSize: 10, color: "gray" }}>UID# {`P${formatUID(product.product_uid)}`}</span>
                            </div>
                          </td>
                          <td
                            style={{
                              ...styles.td,
                              textAlign: "left",
                              alignContent: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                height: "40px",
                                alignContent: "center",
                                fontSize: 14,
                                display: "flex",
                                marginRight: 10,
                              }}
                            >
                              {product.brand_name || ""}
                            </div>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              alignContent: "flex-start",
                            }}
                          >
                            <FormControl fullWidth sx={{ margin: "0px !important" }}>
                              <OutlinedInput
                                name="listing_price"
                                value={product.listing_price !== null && product.listing_price !== undefined ? product.listing_price : ""}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  handleInputChange(
                                    product.id,
                                    "listing_price",
                                    newValue === "" ? null : newValue, // Allow clearing the input field
                                    "number"
                                  );
                                }}
                                style={{ width: "110px", margin: 0 }}
                                size="small"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                inputMode="decimal"
                                onKeyDown={(e) => handleKeyDown(e, rowIndex, 3)}
                                inputRef={(ref) => (inputRefs.current[`${rowIndex}-3`] = ref)}
                              />
                            </FormControl>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              backgroundColor: "#fafafa",
                              alignContent: "flex-start",
                            }}
                          >
                            <FormControl fullWidth sx={{}}>
                              <OutlinedInput
                                name="listing_limit_per_order"
                                // type="number"
                                value={
                                  product.listing_limit_per_order !== null && product.listing_limit_per_order !== undefined
                                    ? product.listing_limit_per_order
                                    : ""
                                }
                                onChange={(e) => handleInputChange(product.id, "listing_limit_per_order", e.target.value, "number")}
                                style={{ width: "100px" }}
                                size="small"
                                inputMode="number"
                                onKeyDown={(e) => handleKeyDown(e, rowIndex, 4)}
                                inputRef={(ref) => (inputRefs.current[`${rowIndex}-4`] = ref)}
                              />
                            </FormControl>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              alignContent: "flex-start",
                            }}
                          >
                            <FormControl fullWidth sx={{}}>
                              <OutlinedInput
                                name="listing_stock_amount"
                                // type="number"
                                value={product.listing_stock_amount !== null && product.listing_stock_amount !== undefined ? product.listing_stock_amount : ""}
                                onChange={(e) => handleInputChange(product.id, "listing_stock_amount", e.target.value, "number")}
                                style={{ width: "90px" }}
                                size="small"
                                inputMode="number"
                                onKeyDown={(e) => handleKeyDown(e, rowIndex, 5)}
                                inputRef={(ref) => (inputRefs.current[`${rowIndex}-5`] = ref)}
                              />
                            </FormControl>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              borderLeft: ".5px solid gray",
                              paddingLeft: "5px",
                              alignContent: "flex-start",
                            }}
                          >
                            <FormControl fullWidth sx={{}}>
                              <OutlinedInput
                                name="listing_sale_price"
                                //  type="number"
                                value={product.listing_sale_price !== null && product.listing_sale_price !== undefined ? product.listing_sale_price : ""}
                                onChange={(e) => handleInputChange(product.id, "listing_sale_price", e.target.value, "number")}
                                style={{
                                  width: "120px",
                                  backgroundColor: "#dffddf",
                                }}
                                size="small"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                inputMode="decimal"
                                onKeyDown={(e) => handleKeyDown(e, rowIndex, 6)}
                                inputRef={(ref) => (inputRefs.current[`${rowIndex}-6`] = ref)}
                              />
                            </FormControl>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              alignContent: "flex-start",
                            }}
                          >
                            <FormControl fullWidth sx={{}}>
                              <OutlinedInput
                                name="listing_sale_percent"
                                //  type="number"
                                value={product.listing_sale_percent !== null && product.listing_sale_percent !== undefined ? product.listing_sale_percent : ""}
                                onChange={(e) => handleInputChange(product.id, "listing_sale_percent", e.target.value, "number")}
                                style={{
                                  width: "120px",
                                  backgroundColor: "#dffddf",
                                }}
                                size="small"
                                inputMode="decimal"
                                onKeyDown={(e) => handleKeyDown(e, rowIndex, 7)}
                                inputRef={(ref) => (inputRefs.current[`${rowIndex}-7`] = ref)}
                              />
                            </FormControl>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              alignContent: "flex-start",
                            }}
                          >
                            <input
                              name="listing_sale_startDate"
                              type="datetime-local"
                              value={product.listing_sale_startDate || ""}
                              onChange={(e) => handleInputChange(product.id, "listing_sale_startDate", e.target.value)}
                              style={{
                                ...styles.formInput,
                                width: 150,
                                backgroundColor: "#e0f8fd",
                                padding: "8px",
                              }}
                            />
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              borderRight: ".5px solid gray",
                              paddingRight: "5px",
                              alignContent: "flex-start",
                            }}
                          >
                            <input
                              name="listing_sale_endDate"
                              type="datetime-local"
                              value={product.listing_sale_endDate || ""}
                              onChange={(e) => handleInputChange(product.id, "listing_sale_endDate", e.target.value)}
                              style={{
                                ...styles.formInput,
                                width: 150,
                                backgroundColor: "#e0f8fd",
                                padding: "8px",
                              }}
                            />
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              alignContent: "flex-start",
                            }}
                          >
                            <FormControl fullWidth sx={{}}>
                              <OutlinedInput
                                name="listing_product_description"
                                type="text"
                                value={product.listing_product_description || ""}
                                onChange={(e) => handleInputChange(product.id, "listing_product_description", e.target.value)}
                                style={{ width: 120 }}
                                onKeyDown={(e) => handleKeyDown(e, rowIndex, 10)}
                                inputRef={(ref) => (inputRefs.current[`${rowIndex}-10`] = ref)}
                                size="small"
                              />
                            </FormControl>
                          </td>

                          <td
                            style={{
                              ...styles.td,
                              alignSelf: "flex-end",
                              alignContent: "flex-start",
                            }}
                          >
                            <input
                              name="listing_product_notAvailable"
                              type="checkbox"
                              checked={product.listing_product_notAvailable || false}
                              onChange={(e) => handleInputChange(product.id, "listing_product_notAvailable", e.target.checked)}
                              style={{ height: 20, width: 20 }}
                            />
                          </td>
                        </tr>
                      </Tooltip>
                    ))}
                  </tbody>
                </table>
                <div style={{ marginLeft: 15, marginTop: 50 }}>
                  <button
                    type="submit"
                    style={{
                      ...styles.button,
                      backgroundColor: loading ? "silver" : "#478169",
                    }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <p style={styles.noProducts}>No products available.</p>
          )}
        </>
      ) : (
        <p style={styles.loadingText}>Loading store data...</p>
      )}
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px",
  },
  storeListItem: {
    display: "flex",
    padding: "10px 0",
    width: "100%",
    cursor: "pointer",
  },
  //
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
    width: 100,
    marginRight: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
    width: 50,
    textAlign: "left",
    backgroundColor: "rgba(255,255,255,.8)",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  th: {
    padding: 10,
    textAlign: "left",
    marginBottom: 20,
    fontSize: 12,
    minWidth: 70,
  },
  td: {
    textAlign: "center",
  },
};

export default EditListings;
